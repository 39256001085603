import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Header from "../components/header";

export default function Default() {
	return (
		<div>
			<Header />
			<Container>
				<Alert variant="danger">
					<b>Error 404</b>: The requested page could not be found.
				</Alert>
			</Container>
		</div>
	);
}

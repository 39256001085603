import * as Yup from "yup";
import { Formik } from "formik";
import { NavLink } from "react-router-dom";
import { UnbanRequestResponse } from "../types/websiteTypes";
import { serialize } from "object-to-formdata";
import { useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Header from "../components/header";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import axios, { AxiosProgressEvent } from "axios";
import useAuthorization from "../hooks/useAuthorization";

const schema = Yup.object().shape({
	banReason: Yup.string().required("Ban reason is required."),
	unbanExplanation: Yup.string().required("Unban explanation is required."),
});

export default function UnbanRequest() {
	const [showResponseModal, setShowResponseModal] = useState(false);
	const [unbanRequestResponse, setUnbanRequestResponse] = useState<UnbanRequestResponse>({
		success: false,
		message: "",
	});

	const [sendingProgress, setSendingProgress] = useState<AxiosProgressEvent>();

	const handleError = useCallback((error: string, errorDescription: string) => {
		const message = `An error occurred while authenticating: ${error} - ${errorDescription}`;
		console.error(message);

		setShowResponseModal(true);
		setUnbanRequestResponse({
			message: message,
			success: false,
		});
	}, []);

	const sendUnbanRequest = useCallback(async (code: string, value: object) => {
		const formData = serialize(value, {
			noAttributesWithArrayNotation: true,
			noFilesWithArrayNotation: true,
		});

		const response = await axios.post(
			`https://api.emergency-hamburg.com/public/unban-request?code=${code}`,
			formData,
			{
				onUploadProgress: (progressEvent) => setSendingProgress(progressEvent),
				validateStatus: () => true,
			},
		);

		setSendingProgress(undefined);

		setShowResponseModal(true);

		if (response.status === 200) {
			setUnbanRequestResponse(response.data);
		} else {
			if (response.status === 429) {
				setUnbanRequestResponse({
					success: false,
					message: "You tried to submit too many unban requests. Please try again later.",
				});
				return;
			}

			console.error(
				`An error code ${response.status} occurred while sending the unban request: ${response.statusText}`,
			);

			setUnbanRequestResponse({
				success: false,
				message: `Unban Request Error: ${response.statusText}`,
			});
		}
	}, []);

	const authorize = useAuthorization(sendUnbanRequest, handleError);

	return (
		<div>
			<Header />
			<Container>
				<h4>Unban Request</h4>
				<Formik
					validationSchema={schema}
					onSubmit={authorize}
					validateOnMount
					initialValues={{
						banReason: "",
						unbanExplanation: "",
					}}>
					{({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Form.Group className="mt-3">
								<Form.Label className="d-block mb-0">Ban Reason</Form.Label>
								<Form.Text>
									Please provide us the ban reason shown when you try joining the game.
								</Form.Text>
								<InputGroup hasValidation>
									<InputGroup.Text>Reason:</InputGroup.Text>
									<Form.Control
										type="text"
										name="banReason"
										value={values.banReason}
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={touched.banReason && !!errors.banReason}
									/>
									<Form.Control.Feedback type="invalid">{errors.banReason}</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>

							<Form.Group className="mt-3">
								<Form.Label className="d-block mb-0">Unban Explanation</Form.Label>
								<Form.Text>
									Please provide us with an explanation why you were wrongfully banned. Note that
									exploiters will not be unbanned.
								</Form.Text>
								<Form.Control
									as="textarea"
									name="unbanExplanation"
									value={values.unbanExplanation}
									onChange={handleChange}
									onBlur={handleBlur}
									rows={3}
									isInvalid={touched.unbanExplanation && !!errors.unbanExplanation}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.unbanExplanation}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mt-3 mb-3">
								<Form.Label className="d-block mb-2">
									A verification popup will be shown where you have to verify your roblox account to
									complete your unban request. Please note that
									<b> you have to select your banned account</b> - otherwise, we cannot process your
									unban request.{" "}
									<b>
										<a
											target="_blank"
											href="https://www.roblox.com/games/7711635737/Emergency-Hamburg"
											rel="noreferrer">
											Turn on notifications
										</a>
									</b>{" "}
									for our game to be notified on Roblox once your unban request has been reviewed by
									one of our moderators.
								</Form.Label>
								<Button type="submit" variant="success">
									Submit Unban Request
								</Button>
							</Form.Group>
						</Form>
					)}
				</Formik>
			</Container>

			<Modal
				show={showResponseModal}
				centered
				backdrop="static"
				keyboard={false}
				onHide={unbanRequestResponse.success ? undefined : () => setShowResponseModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{unbanRequestResponse.success ? "Unban Request Sent" : "Failed to send Unban Request"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>{unbanRequestResponse.message}</p>
				</Modal.Body>
				<Modal.Footer>
					{unbanRequestResponse.success ? (
						<NavLink to="/">
							<Button>Close</Button>
						</NavLink>
					) : (
						<Button onClick={() => setShowResponseModal(false)}>Close</Button>
					)}{" "}
				</Modal.Footer>
			</Modal>

			<Modal show={!!sendingProgress} centered backdrop="static" keyboard={false}>
				<Modal.Header>
					<Modal.Title>Your unban request is sending.</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>{Math.round(sendingProgress?.estimated ?? 0)} seconds remaining.</p>
					<ProgressBar
						animated
						variant="success"
						now={((sendingProgress?.loaded ?? 0) / (sendingProgress?.total ?? 1)) * 100}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
}

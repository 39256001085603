import { useMemo } from "react";
import Fuse, { IFuseOptions } from "fuse.js";

export default function useSearch<T>(items: T[], search: string, options: IFuseOptions<T>) {
	const fuse = useMemo(() => new Fuse(items, options), [items, options]);

	const results = useMemo(() => {
		return search ? fuse.search(search).map((result) => result.item) : items;
	}, [search, fuse, items]);

	return results;
}

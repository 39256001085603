import { useCallback, useEffect, useState } from "react";
import Cookies from "js-cookie";

function createAuthorizationWindow() {
	const options = "width=700, height=900, left=50, top=50";

	return window.open(
		"https://api.emergency-hamburg.com/public/authorize",
		"Authorization",
		options,
	);
}

export default function useAuthorization<T extends NonNullable<unknown>>(
	onAuthorized: (code: string, value: T) => void,
	onError: (error: string, errorDescription: string) => void,
) {
	const [authorizationValue, setAuthorizationValue] = useState<T>();

	const removeCookies = useCallback(() => {
		Cookies.remove("code");
		Cookies.remove("error");
		Cookies.remove("errorDescription");
	}, []);

	const authorize = useCallback(
		(value: T) => {
			removeCookies();
			setAuthorizationValue(value);

			createAuthorizationWindow();
		},
		[removeCookies],
	);

	useEffect(() => {
		if (authorizationValue === undefined) return;

		const intervalId = setInterval(() => {
			const cookies = Cookies.get();

			if (cookies.code) {
				onAuthorized(cookies.code, authorizationValue);
				removeCookies();

				setAuthorizationValue(undefined);
				return;
			}

			if (cookies.error) {
				onError(cookies.error, cookies.errorDescription || "");
				removeCookies();

				setAuthorizationValue(undefined);
				return;
			}
		}, 100);

		return () => clearInterval(intervalId);
	}, [authorizationValue, onAuthorized, onError, removeCookies]);

	return authorize;
}

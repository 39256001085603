import { useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Cookies from "js-cookie";
import useQuery from "../hooks/useQuery";

export default function Authorized() {
	const searchParams = useQuery();

	useEffect(() => {
		const code = searchParams.get("code");

		if (code) {
			Cookies.set("code", code);
			window.close();
			return;
		}

		const error = searchParams.get("error");
		const errorDescription = searchParams.get("error_description");

		if (error) {
			const description = errorDescription?.replace("+", " ") ?? "";

			Cookies.set("error", error);
			Cookies.set("errorDescription", description);

			window.close();
			return;
		}
	}, [searchParams]);

	return (
		<div>
			<Container>
				<Alert className="mt-2" variant="info">
					<b>Authorization completed</b>: This window will be closed automatically.
				</Alert>
			</Container>
		</div>
	);
}

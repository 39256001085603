import { ReservedServerInfo } from "../types/websiteTypes";
import { useEffect, useMemo, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Header from "../components/header";
import ReservedServer from "../components/reservedServer";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import useSearch from "../hooks/useSearch";

export default function PrivateServers() {
	const [servers, setServers] = useState<ReservedServerInfo[]>();
	const [error, setError] = useState<Error>();

	const [search, setSearch] = useState("");
	const [showFull, setShowFull] = useState(false);

	const filteredServers = useMemo(() => {
		if (!servers) return;
		if (showFull) return servers;

		return servers.filter((server) => server.currentPlayers < server.maxPlayers - 1);
	}, [servers, showFull]);

	const serverSearch = useSearch(filteredServers ?? [], search, {
		keys: ["serverName", "serverDescription"],
	});

	useEffect(() => {
		(async () => {
			try {
				const response = await fetch("https://api.emergency-hamburg.com/public/servers");

				if (!response.ok) {
					throw new Error(`Failed to fetch servers with status ${response.status}.`);
				}

				const data = await response.json();
				setServers(data);
			} catch (error) {
				setError(error as Error);
			}
		})();
	}, []);

	return (
		<div>
			<Header />
			<Container>
				<h4>Private Servers</h4>
				<div className="mb-3">
					Private servers are communities in Emergency Hamburg that are often used for roleplaying.
					Here you can join one of the currently active servers.
				</div>

				<FloatingLabel
					label={
						<div>
							<i className="bi bi-search" /> Search for servers
						</div>
					}
					className="mb-2">
					<Form.Control
						className="shadow border-0"
						placeholder=""
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</FloatingLabel>

				<Form.Check
					label="Include full servers"
					className="mb-3"
					checked={showFull}
					onChange={(e) => setShowFull(e.target.checked)}
				/>

				{!servers ? (
					<Alert variant={error ? "danger" : "primary"}>
						{error ? (
							error.message
						) : (
							<span>
								<Spinner size="sm" className="me-1" />
								Loading...
							</span>
						)}
					</Alert>
				) : serverSearch.length === 0 ? (
					<Alert variant="warning fw-bold">
						<i className="bi bi-exclamation-triangle-fill me-1" />
						No servers found.
					</Alert>
				) : (
					<Row>
						{serverSearch.map((server) => (
							<Col xs={12} md={6} key={server.privateServerId}>
								<ReservedServer {...server} />
							</Col>
						))}
					</Row>
				)}
			</Container>
		</div>
	);
}

import * as Yup from "yup";
import { Formik } from "formik";
import { Link, NavLink } from "react-router-dom";
import { PlayerReportResponse } from "../types/websiteTypes";
import { reportOptions } from "../modules/reportOptions";
import { serialize } from "object-to-formdata";
import { useCallback, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Header from "../components/header";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import axios, { AxiosProgressEvent } from "axios";
import useAuthorization from "../hooks/useAuthorization";

function getFileListSize(fileList: FileList) {
	let size = 0;

	Array.from(fileList).forEach((file) => {
		size += file.size;
	});

	return size / (1024 * 1024);
}

const schema = Yup.object().shape({
	username: Yup.string().required("Username is required."),
	ruleViolation: Yup.string().required("A rule violation is required."),
	evidence: Yup.mixed()
		.required("Evidence is required.")
		.test("fileExists", "Evidence is required.", (value) => {
			const fileList = value as FileList | undefined;
			if (!fileList) return true;

			return fileList.length > 0;
		})
		.test("fileSize", "Evidence files can not be larger than 80MB.", (value) => {
			const fileList = value as FileList | undefined;
			if (!fileList) return true;

			return getFileListSize(fileList) < 80;
		}),
});

export default function PlayerReport() {
	const [showResponseModal, setShowResponseModal] = useState(false);
	const [playerReportResponse, setPlayerReportResponse] = useState<PlayerReportResponse>({
		success: false,
		message: "",
	});

	const [sendingProgress, setSendingProgress] = useState<AxiosProgressEvent>();

	const handleError = useCallback((error: string, errorDescription: string) => {
		const message = `An error occurred while authenticating: ${error} - ${errorDescription}`;
		console.error(message);

		setShowResponseModal(true);
		setPlayerReportResponse({
			message: message,
			success: false,
		});
	}, []);

	const sendReport = useCallback(async (code: string, value: object) => {
		const formData = serialize(value, {
			noAttributesWithArrayNotation: true,
			noFilesWithArrayNotation: true,
		});

		const response = await axios.post(
			`https://api.emergency-hamburg.com/public/report-player?code=${code}`,
			formData,
			{
				onUploadProgress: (progressEvent) => setSendingProgress(progressEvent),
				validateStatus: () => true,
			},
		);

		setSendingProgress(undefined);

		setShowResponseModal(true);

		if (response.status === 200) {
			setPlayerReportResponse(response.data);
		} else {
			if (response.status === 429) {
				setPlayerReportResponse({
					success: false,
					message: "You tried to submit too many player reports. Please try again later.",
				});
				return;
			}

			console.error(
				`An error code ${response.status} occurred while sending the player report: ${response.statusText}`,
			);

			setPlayerReportResponse({
				success: false,
				message: `Player Report Error: ${response.statusText}`,
			});
		}
	}, []);

	const authorize = useAuthorization(sendReport, handleError);

	return (
		<div>
			<Header />
			<Container>
				<h4>Player Report</h4>
				<Formik
					validationSchema={schema}
					onSubmit={authorize}
					validateOnMount
					initialValues={{
						username: "",
						additionalInformation: "",
						ruleViolation: "",
						evidence: undefined as undefined | FileList,
					}}>
					{({
						handleSubmit,
						handleChange,
						handleBlur,
						setFieldValue,
						setFieldTouched,
						values,
						errors,
						touched,
					}) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Form.Group className="mt-3">
								<Form.Label className="d-block mb-0">Rule Violator Roblox Username</Form.Label>
								<Form.Text>
									Please provide us the Roblox username of the rule violator, not the display name.
								</Form.Text>
								<InputGroup hasValidation>
									<InputGroup.Text>@</InputGroup.Text>
									<Form.Control
										type="text"
										placeholder="Username"
										name="username"
										value={values.username}
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={touched.username && !!errors.username}
									/>
									<Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>

							<Form.Group className="mt-3">
								<Form.Label className="d-block mb-0">Rule Violation</Form.Label>
								<Form.Text>
									Please select the rule violation the user committed. We do not moderate insults or
									other chat issues, please contact{" "}
									<a target="_blank" href="https://www.roblox.com/support" rel="noreferrer">
										Roblox support
									</a>{" "}
									for that. Note that fail roleplay and teaming are not rule violations.
								</Form.Text>

								{reportOptions.map((option, index) => (
									<div key={index}>
										<Form.Check
											className="mb-0 mt-2"
											name="ruleViolation"
											type="radio"
											onChange={handleChange}
											onBlur={handleBlur}
											label={option.name}
											value={option.ruleViolation}
											isInvalid={touched.ruleViolation && !!errors.ruleViolation}
										/>
										<Form.Text> {option.description} </Form.Text>
									</div>
								))}
								{touched.ruleViolation && errors.ruleViolation && (
									<Form.Text className="d-block text-danger">{errors.ruleViolation}</Form.Text>
								)}
							</Form.Group>

							{values.ruleViolation === "roleplay" ? (
								<Alert className="mt-2" variant="warning">
									<h5>Report cannot be sent</h5>
									Fail roleplay and teaming are not rule violations. For a better roleplay
									experience, you can join a private roleplay server{" "}
									<Link to="/private-servers">here</Link>.
								</Alert>
							) : values.ruleViolation === "insulting" || values.ruleViolation === "roblox" ? (
								<>
									<Alert className="mt-2" variant="success">
										The rule violation falls within the responsibilities of Roblox. On the Roblox
										support page, select "Moderation" and "Report User Breaking Rules" in issue
										details and provide the necessary information.
									</Alert>
									<Button
										className="mb-3"
										href="https://www.roblox.com/support"
										target="_blank"
										variant="success">
										Continue Report
									</Button>
								</>
							) : (
								<>
									<Form.Group className="mt-3">
										<Form.Label className="d-block mb-0">Evidence</Form.Label>
										<Form.Text>
											Please provide us with video or image evidence regarding your report.
										</Form.Text>
										<Form.Control
											type="file"
											multiple
											accept="video/*,image/*"
											onChange={(event) => {
												const target = event.target as HTMLInputElement;

												setFieldTouched("evidence");
												setFieldValue("evidence", target.files);
											}}
											isInvalid={touched.evidence && !!errors.evidence}
										/>
										<Form.Control.Feedback type="invalid">{errors.evidence}</Form.Control.Feedback>
									</Form.Group>

									<Form.Group className="mt-3">
										<Form.Label className="d-block mb-0">Additional Information</Form.Label>
										<Form.Text>
											Please provide us with any additional information regarding your report, e.g.
											the timestamp in a video where the rule violation occurs.
										</Form.Text>
										<Form.Control
											as="textarea"
											name="additionalInformation"
											value={values.additionalInformation}
											onChange={handleChange}
											onBlur={handleBlur}
											rows={3}
										/>
									</Form.Group>

									<Form.Group className="mt-3 mb-3">
										<Form.Label className="d-block mb-2">
											A verification popup will be shown where you have to verify your Roblox
											account to complete your report. Submitting false or spam reports may result
											in moderation action.{" "}
											<b>
												<a
													target="_blank"
													href="https://www.roblox.com/games/7711635737/Emergency-Hamburg"
													rel="noreferrer">
													Turn on notifications
												</a>
											</b>{" "}
											for our game to be notified on Roblox once your report has been reviewed by
											one of our moderators.
										</Form.Label>
										<Button type="submit" variant="success">
											Submit Report
										</Button>
									</Form.Group>
								</>
							)}
						</Form>
					)}
				</Formik>
			</Container>

			<Modal
				show={showResponseModal}
				centered
				backdrop="static"
				keyboard={false}
				onHide={playerReportResponse.success ? undefined : () => setShowResponseModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{playerReportResponse.success ? "Player Report Sent" : "Failed to send Player Report"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>{playerReportResponse.message}</p>
				</Modal.Body>
				<Modal.Footer>
					{playerReportResponse.success ? (
						<NavLink to="/">
							<Button>Close</Button>
						</NavLink>
					) : (
						<Button onClick={() => setShowResponseModal(false)}>Close</Button>
					)}{" "}
				</Modal.Footer>
			</Modal>

			<Modal show={!!sendingProgress} centered backdrop="static" keyboard={false}>
				<Modal.Header>
					<Modal.Title>Your report is sending.</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>{Math.round(sendingProgress?.estimated ?? 0)} seconds remaining.</p>
					<ProgressBar
						animated
						variant="success"
						now={((sendingProgress?.loaded ?? 0) / (sendingProgress?.total ?? 1)) * 100}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
}
